import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import getFeatureFlags, { FeatureFlags } from '../utils/apiRestCalls/getFeatureFlags';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export const initialfeatureFlagValues: FeatureFlags = {
  test: false,
  enable_company_phone_for_smb: false,
  is_new_smb_portfolio_amount: false,
  enable_old_size_of_portfolio: true,
  enable_new_renter_invites_for_smb: true,
  enable_new_deals_dashboard: false,
  enable_new_renters_insurance_dashboard: false,
  enable_person_signup_image: false,
  enable_footer_trust_center: false,
  enable_footer_marketplace: false,
  enable_footer_blog_rename: false,
  remove_footer_contact_us: false,
};

const featureflagStore = create(
  devtools<{
    featureFlags: FeatureFlags;
    fetchFeatureFlags: () => Promise<void>;
  }>((set) => ({
    featureFlags: initialfeatureFlagValues,
    fetchFeatureFlags: async () => {
      const { data: featureFlags } = useQuery({
        queryKey: ['featureFlag'],
        queryFn: () => getFeatureFlags(),
        staleTime: Infinity,
      });
      useEffect(() => {
        if (featureFlags) {
          set({ featureFlags: featureFlags });
        }
      }, [featureFlags]);
    },
  })),
);

export default featureflagStore;
