import { createTheme, ThemeOptions } from '@mui/material/styles';
import breakpoints from './themeBreakpoints';
import typography from './themeTypography';
import { checkboxClasses, responsiveFontSizes, Theme } from '@mui/material';
import palette from './themePalette';
import { CheckCircleOutline } from '@mui/icons-material';

type ThemeArgs = {
  theme: Omit<Theme, 'components'>;
};

export const theme: ThemeOptions = {
  transitions: {
    duration: {
      enteringScreen: 750,
      leavingScreen: 500,
    },
  },
  breakpoints,
  palette,
  typography,
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        component: 'nav',
      },
      styleOverrides: {
        root: () => ({
          backgroundColor: 'white',
          boxShadow: '4px 16px 32px rgba(0, 0, 0, 0.08)',
          marginTop: '10px',
          zIndex: 699,
          [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
            marginTop: '20px',
            left: '20px',
            right: '20px',
            width: 'auto',
            borderRadius: 8,
          },
        }),
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${palette.border.secondary}`,
          borderBottom: 0,
          borderRadius: '8px',
          boxShadow: 'none',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          [' .MuiTableCell-root']: {
            fontSize: '14px',
          },
          [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
            ['> .MuiTableCell-root']: {
              fontSize: '14px',
            },
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            color: 'inherit !important',
          },
          '&:hover:not(:has(.toggleArrow:hover))': {
            color: `${theme.palette.text.secondary} !important`,
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: '#ffffff',
          '&.subRow': {
            backgroundColor: '#f5f5f7',
          },
          '&:hover:not(:has(.toggleArrow:hover))': {
            backgroundColor: theme.palette.action.hover,
          },
          ['.MuiTableHead-root > &']: {
            backgroundColor: '#f5f5f7',
            '&:hover:not(:has(.toggleArrow:hover))': { backgroundColor: '#f5f5f7' },
          },
          ['.MuiTableFooter-root > &']: {
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          padding: '20px 12px 7px 12px',
          lineHeight: '32px',
          [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
            fontSize: '16px',
          },
          borderBottom: '1px solid rgba(8, 10, 45, 0.3)',
          '.MuiChip-label': {
            fontSize: '12px',
          },
          '&.break-word': {
            maxWidth: 0,
            overflow: 'hidden',
            wordBreak: 'break-all',
            whiteSpace: 'wrap',
            [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
              maxWidth: '430px',
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          paddingLeft: '16px',
          fontSize: '12px',
          [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
            paddingLeft: '16px',
          },
        },
        spacer: {
          order: 2,
          [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
            order: 1,
          },
        },
        selectLabel: ({ theme }) => ({
          order: 1,
          fontSize: '12px',
          color: theme.palette.text.secondary,
        }),
        select: {
          option: {
            paddingTop: '1px',
            paddingRight: '3px',
          },
        },
        input: {
          order: 1,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          minHeight: '50px',
          paddingLeft: '20px',
          paddingRight: '20px',
          [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
            minHeight: '80px',
            paddingLeft: '40px',
            paddingRight: '40px',
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterTouchDelay: 0,
        arrow: true,
        PopperProps: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: () => [0, -5],
              },
            },
          ],
        },
      },
      styleOverrides: {
        arrow: ({ theme }) => ({
          color: theme.palette.primary.main,
        }),
        tooltip: ({ theme }) => ({
          lineHeight: '14px',
          fontWeight: '400',
          fontSize: '10px',
          letterSpacing: '.4px',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.light,
          maxWidth: '220px',
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${checkboxClasses.checked}`]: {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '4px 16px 32px 0px rgba(29, 29, 29, 0.08)',
          borderRadius: '4px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          '.Mui-disabled &': {
            color: `${theme.palette.grey[400]}`,
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: `${theme.palette.primary.light}`,
          '&.Mui-selected': {
            backgroundColor: `${theme.palette.secondary.dark}`,
            '&:hover': {
              backgroundColor: `${theme.palette.secondary.dark}`,
            },
          },
          '&:hover': {
            backgroundColor: `${theme.palette.secondary.light}`,
          },
          '&.Mui-focusVisible': {
            backgroundColor: `${theme.palette.secondary.light}`,
          },
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: () => ({
          '&.Mui-disabled': {
            opacity: 1,
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true as boolean,
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'capitalize',
          lineHeight: 'unset',
          padding: '16px 30px',
          border: '1px solid',
          fontSize: '16px',
          fontWeight: '400',
          letterSpacing: '0.32px',
          borderRadius: '10px',
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            width: '100%',
            // padding: '16px',
          },
          ...(ownerState.variant === 'contained' && {
            color: theme.palette.primary.light,
            '&:hover': {
              color: theme.palette.primary.contrastText,
              [`@media (pointer:coarse)`]: {
                // hacky Mobile fix
                color: theme.palette.primary.light,
              },
            },
            '&:disabled': {
              color: theme.palette.primary.light,
              backgroundColor: '#7D7F90',
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            '&:hover': {
              color: theme.palette.primary.light,
              backgroundColor: theme.palette.primary.main,
            },
            '&.Mui-disabled': {
              pointerEvents: 'auto',
            },
          }),
          ...(ownerState.variant === 'text' && {
            borderColor: theme.palette.primary.light,
            //padding: '5px 10px',
            padding: '16px 30px',
            lineHeight: '26px',
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              width: 'unset',
              padding: 'unset',
            },
          }),
          ...(ownerState.size === 'small' && {
            fontSize: '14px' as string,
            lineHeight: '10px' as string,
            padding: '16px 23px',
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              width: 'auto',
            },
          }),
          loadingIndicator: {
            marginRight: '8px',
          },
        }),
        containedError: ({ theme }) => ({
          '&:hover': {
            color: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        variant: 'determinate',
        color: 'success',
      },
      styleOverrides: {
        root: () => ({
          backgroundColor: '#F8F4EF',
          height: '10px',
          width: '100%',
          zIndex: '100',
        }),
        bar: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: 'currentcolor',
          '&[data-variant="navItem"]': {
            position: 'relative',
            textDecoration: 'none',
            color: 'inherit',
            [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
              fontSize: '12px' as string,
            },
            '&:hover::after': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&::after': {
              content: '""',
              position: 'absolute',
              bottom: '-33px',
              [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
                bottom: '-19px',
              },
              left: 0,
              width: '100%',
              height: '2.2px',
              backgroundColor: 'transparent',
              transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1)',
            },
          },
          '&[data-variant="noColor"]': {
            textDecoration: 'underline',
            borderBottom: '0',
          },
        }),
        underlineAlways: ({ theme }) => ({
          textDecoration: 'none',
          padding: '0 1px 2px 0',
          borderBottom: '1px solid',
          transition: 'border-color 0.3s linear',
          '&:hover': {
            borderBottomColor: theme.palette.secondary.main,
          },
        }),
      },
    },

    MuiTextField: {
      defaultProps: {
        InputProps: { disableUnderline: true },
        variant: 'filled',
        margin: 'dense',
        fullWidth: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: palette.text?.secondary,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.text?.secondary,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#ECF7EE!important',
          borderRadius: 4,
          border: '1px solid #FFF',
          '&:hover': {
            backgroundColor: 'rgba(61, 177, 87, .15)!important',
            border: '1px solid #1D1D1D',
          },
          '&.Mui-error': {
            border: '1px solid rgba(255, 48, 48, 1)!important',
          },
          '&::before': {
            borderBottom: '1px solid #FFF',
          },
          '&::after': {
            borderBottom: '1px solid white!important',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFF!important',
          borderRadius: 4,
          border: '0 solid #000',
          ['&:focus, &:hover']: {
            backgroundColor: '#EBEBEE!important',
            border: '0px solid !important',
          },
          '&.Mui-error': {
            border: '1px solid rgba(255, 48, 48, 1)!important',
          },
          '&::before': {
            borderBottom: '1px solid #FFF',
          },
          '&::after': {
            borderBottom: '1px solid white!important',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.fontSize === 'small' && {
            fontSize: '10px',
            padding: '1px 7px',
          }),
        }),
      },
    },
    // @ts-expect-error typings are not exported?
    MuiPickersDay: {
      styleOverrides: {
        root: ({ theme }: ThemeArgs) => ({
          ['&.Mui-selected']: {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.primary,
            ['&:focus, &:hover']: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.light,
            },
          },
        }),
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        selected: ({ theme }: ThemeArgs) => ({
          color: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.main,
          ['&:focus, &:hover']: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        selected: ({ theme }: ThemeArgs) => ({
          color: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.main,
          ['&:focus.Mui-selected']: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.light,
          },
        }),
        root: ({ theme }: ThemeArgs) => ({
          ['&:focus.Mui-selected']: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: '1px solid',
          color: theme.palette.grey[100],
        }),
        filledSecondary: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.light,
          borderColor: theme.palette.secondary.main,
        }),
        filled: ({ theme }) => ({
          backgroundColor: theme.palette.grey[600],
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '32px 32px 4px',
          [`@media screen and (max-width: ${breakpoints.values.sm}px)`]: {
            fontSize: '20px',
          },
          [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
            fontSize: '24px',
            padding: '40px 40px 8px',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 32px 32px',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
            fontSize: '16px',
          },
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 32px 32px',
          button: {
            padding: '16px 30px',
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          top: 'auto',
          bottom: '24px',
          left: '24px',
          right: '24px',
          maxWidth: '360px',
          zIndex: 2147483001,
          [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
            top: 'auto',
            bottom: '24px',
            left: 'auto',
            right: '24px',
            transform: 'none',
          },
          [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
            top: '24px',
            bottom: 'auto',
            left: 'auto',
            right: '24px',
            transform: 'none',
          },
          '.MuiAlert-message': {
            fontSize: '16px',
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          success: <CheckCircleOutline />,
        },
      },
      styleOverrides: {
        action: {
          margin: '-6px -12px 0 0',
          '.MuiSvgIcon-root': {
            fontSize: '24px',
          },
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
  },
};

export const materialTheme = responsiveFontSizes(createTheme(theme));
