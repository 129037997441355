import { MuiSxCollection } from '@/features/RentersInsurance/types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import palette from '@/theme/themePalette';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useState } from 'react';

const mobileViewPopupStyles = {
  paper: {
    py: '40px',
    px: '30px',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '32px',
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    columnGap: '16px',
  },
  drawerClose: {
    padding: 0,
    color: palette.text.secondary,
  },
  drawerText: (theme) => {
    const textBreakpoint = theme.breakpoints.down('md');

    return {
      lineHeight: '24px',
      [textBreakpoint]: {
        fontSize: '16px',
      },
    };
  },
} satisfies MuiSxCollection;

export function MobileViewPopup() {
  const theme = useTheme();
  const mobileWidthQuery = theme.breakpoints.down(751);
  const isMobile = useMediaQuery(mobileWidthQuery);

  const [isPopupOpen, setIsPopupOpen] = useState(true);

  function handleClosePopup() {
    setIsPopupOpen(false);
  }

  if (!isMobile) {
    return null;
  }

  return (
    <Drawer
      open={isPopupOpen}
      anchor="bottom"
      PaperProps={{
        sx: mobileViewPopupStyles.paper,
      }}
      onClose={handleClosePopup}
    >
      <Box component={'header'} sx={mobileViewPopupStyles.drawerHeader}>
        <Typography variant="h4" component={'h2'} lineHeight={'32px'}>
          We are updating our mobile experience
        </Typography>
        <IconButton
          onClick={handleClosePopup}
          aria-label="close"
          sx={mobileViewPopupStyles.drawerClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography sx={mobileViewPopupStyles.drawerText}>
        For the best experience, please view this table on your desktop or web device.
      </Typography>
      <Button onClick={handleClosePopup} variant="contained">
        Close
      </Button>
    </Drawer>
  );
}
