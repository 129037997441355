import { apiUri } from '@/constants/Constants';
import {
  RiApplicationComplianceStatusEnum,
  RiApplicationPolicyStatusEnum,
  RiApplicationSourceQueryParamsEnum,
  RiSortByParamEnum,
  RiSortOrderParamEnum,
  TimeDuration,
} from '../constants';
import { RiListApplication } from '../types';
import { generateUrlSearchParams, mapRiApplicationSearchParams } from '../utils';
import { client } from '@/utils/axios/axiosClient';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export interface ListRiApplicationsResponse {
  result: RiListApplication[];
  pagination: {
    limit: number;
    page: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    totalCount: number;
  };
}

export interface ListRiApplicationsUrlParams {
  limit: number;
  page: number;
  search?: string;
  policyStatus?: RiApplicationPolicyStatusEnum[];
  complianceStatus?: RiApplicationComplianceStatusEnum[];
  source?: RiApplicationSourceQueryParamsEnum[];
  sortBy: RiSortByParamEnum;
  sortOrder: RiSortOrderParamEnum;
}

export async function listRiApplications(
  params: ListRiApplicationsUrlParams,
): Promise<ListRiApplicationsResponse> {
  const parsedUrlParams = mapRiApplicationSearchParams(params);
  const urlParams = generateUrlSearchParams(parsedUrlParams);

  const response = await client.get(`${apiUri.listRiApplications}?${urlParams}`, {
    withCredentials: true,
  });

  return response.data;
}

const FALLBACK_CACHE_KEY = 'any';

export const useRiApplicationList = (params: ListRiApplicationsUrlParams) => {
  const { limit, page, complianceStatus, policyStatus, search, sortBy, sortOrder, source } = params;

  const cacheKey = [
    'riApplications',
    page,
    limit,
    complianceStatus ?? FALLBACK_CACHE_KEY,
    policyStatus ?? FALLBACK_CACHE_KEY,
    search ?? FALLBACK_CACHE_KEY,
    sortBy ?? FALLBACK_CACHE_KEY,
    sortOrder ?? FALLBACK_CACHE_KEY,
    source ?? FALLBACK_CACHE_KEY,
  ];

  const data = useQuery({
    queryKey: cacheKey,
    queryFn: () => listRiApplications(params),
    retry: 0,
    placeholderData: keepPreviousData,
    staleTime: TimeDuration.Minute * 2,
    refetchOnWindowFocus: false,
  });

  return data;
};
